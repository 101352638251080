// extracted by mini-css-extract-plugin
export var bottomContainer = "index-module--bottom-container--a43d2";
export var bottomContainerFirstBlock = "index-module--bottom-container-first-block--3c23e";
export var bottomContainerFistItem = "index-module--bottom-container-fist-item--1a331";
export var bottomContainerItem = "index-module--bottom-container-item--8c752";
export var contacts = "index-module--contacts--3cbeb";
export var contactsItem = "index-module--contacts-item--90a6f";
export var content = "index-module--content--7d0ab";
export var last = "index-module--last--feda6";
export var line = "index-module--line--ba0a6";
export var links = "index-module--links--d9585";
export var logo = "index-module--logo--c69fc";
export var penultimate = "index-module--penultimate--e81c7";