// extracted by mini-css-extract-plugin
export var bigOffsetLeft = "index-module--big-offset-left--104e3";
export var bigOffsetRight = "index-module--big-offset-right--19635";
export var blue = "index-module--blue--87788";
export var center = "index-module--center--5f6c6";
export var cream = "index-module--cream--75343";
export var firstLineWrap = "index-module--first-line-wrap--5b3d3";
export var flexColumn = "index-module--flexColumn--b0a9c";
export var headline = "index-module--headline--f2a6a";
export var headlineEn = "index-module--headline-en--bbc08";
export var headlineL1 = "index-module--headline-l1--937bc";
export var headlineL2 = "index-module--headline-l2--93738";
export var headlineL3 = "index-module--headline-l3--a934c";
export var headlineL4 = "index-module--headline-l4--52ae4";
export var hugeOffsetRight = "index-module--huge-offset-right--728d7";
export var hugeOffsetRight2 = "index-module--huge-offset-right2--363c0";
export var middleOffsetRight = "index-module--middle-offset-right--ad335";
export var orange = "index-module--orange--8e071";
export var purple = "index-module--purple--75a76";
export var right = "index-module--right--85dcd";
export var secondLine = "index-module--second-line--df3e0";
export var slide = "index-module--slide--4e217";
export var slider = "index-module--slider--2ff2b";
export var sliderEn = "index-module--slider-en--56f11";
export var smallOffsetRight = "index-module--small-offset-right--8ae66";
export var thirdLine = "index-module--third-line--deb70";
export var thirdLineEn = "index-module--third-line-en--44084";
export var white = "index-module--white--d70d5";
export var zeroOpacity = "index-module--zero-opacity--500a2";