// extracted by mini-css-extract-plugin
export var arrow = "index-module--arrow--5677a";
export var contact = "index-module--contact--5feee";
export var contactHr = "index-module--contact-hr--d2580";
export var content = "index-module--content--0dc5e";
export var email = "index-module--email--9085a";
export var headline = "index-module--headline--c0cd9";
export var root = "index-module--root--b7258";
export var rootHeightAuto = "index-module--root-height-auto--979c1";
export var subtext = "index-module--subtext--9fdb7";
export var vacancies = "index-module--vacancies--ad6ff";
export var vacanciesItem = "index-module--vacancies-item--bf530";
export var vacanciesLink = "index-module--vacancies-link--3c8db";
export var vacanciesText = "index-module--vacancies-text--8026d";
export var vacanciesTextContainer = "index-module--vacancies-text-container--661a6";