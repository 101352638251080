import yuryFrolov from '@/assets/images/reviews/Yury_Frolov.webp';
import nataliaRomanova from '@/assets/images/reviews/Natalia_Romanova.webp';
import dimaIvanov from '@/assets/images/reviews/Dima_Ivanov.webp';
import annaChaplygina from '@/assets/images/reviews/Anna_Chaplygina.webp';
import andreyHrikin from '@/assets/images/reviews/Andrey_Hrikin.webp';
import antonVasserman from '@/assets/images/reviews/Anton_Vasserman.webp';
import sergeyKornilov from '@/assets/images/reviews/Sergey_Kornilov.webp';
import victoriaChernishkina from '@/assets/images/reviews/Victoria_Chernishkina.webp';
import daryaVolodkina from '@/assets/images/reviews/Darya_Volodkina.webp';
import gegamMkrtchjan from '@/assets/images/reviews/Gegam_Mkrtchjan.webp';

export const testimonialsData = {
    title: 'Отзывы',
    content: [
        {
            photo: andreyHrikin,
            name: 'Андрей Хрыкин',
            position: 'ex-Product Owner,\nСберРешения',
            text: [
                'Я\u00a0работал с\u00a0командой SmartHead 1\u00a0год над сложным и\u00a0быстроразвивающимся продуктом для клиентов микро- и\u00a0малого бизнеса крупнейшего банка России. Уровень подхода к\u00a0задачам у\u00a0SmartHead более серьёзный, чем у\u00a0многих известных вендоров. Они умеют адаптировать процесс разработки под нужды заказчика и\u00a0очень требовательны к\u00a0качеству своей работы. Сбалансированные команды позволяют SmartHead брать большие задачи под ключ: от\u00a0сбора требований и\u00a0дизайна до\u00a0построения архитектуры продуктов. Они уже имеют несколько решений до\u00a0того, как вы начинаете обсуждать бизнес-задачу. Поэтому процесс разработки часто опережает лучшие гибкие практики. Рекомендую поработать со\u00a0SmartHead. Вам понравится!',
            ],
        },
        {
            photo: daryaVolodkina,
            name: 'Дарья Володькина',
            position: 'Менеджер спецпроектов,\nСамокат',
            text: [
                'Мы\u00a0обратились к\u00a0SmartHead, чтобы разработать федеральную промоакцию с\u00a0розыгрышем квартиры. Нам нужна была платформа, где все заказы пользователей в\u00a0личном кабинете превращаются в\u00a0волшебные карточки со\u00a0специальными кодами и\u00a0моментальными призами.',
                'С\u00a0момента знакомства с\u00a0ребятами до\u00a0запуска прошло ровно 3\u00a0месяца. За\u00a0это время мы\u00a0запустили сайт, сделали на\u00a0нём игру, а\u00a0ещё «построили мостик» между нашим приложением и\u00a0личным кабинетом пользователя на\u00a0сайте.',
                'Нам важно было найти не\u00a0только хороших разработчиков, но\u00a0и\u00a0классных партнёров. А\u00a0главный показатель успешной коммуникации\u00a0— это\u00a0то, что вместе с\u00a0ребятами мы\u00a0начали думать над новым проектом, когда ещё работали над предыдущим.',
            ],
        },
        {
            photo: sergeyKornilov,
            name: 'Сергей Корнилов',
            position: 'Head of\u00a0IT,\nСберРешения',
            text: [
                'Я\u00a0работаю со\u00a0SmartHead уже более 5\u00a0лет: сначала как технический директор в\u00a0UFS, потом как IT-директор в\u00a0СберРешения. Ребята очень компетентные. Всегда готовы к\u00a0диалогу, подстройке процессов и\u00a0подходов к\u00a0работе с\u00a0учетом ожиданий всех сторон.',
                'Работа со\u00a0SmartHead\u00a0\u2014 это всегда доверительные и\u00a0комфортные отношения. SmartHead предлагает не\u00a0просто время разработчиков, а\u00a0выделяет полноценные, сработанные команды со\u00a0своими процессами, инженерной культурой, высокой планкой качества. Такой подход помогает серьёзно сократить затраты на\u00a0менеджмент команды и\u00a0получить максимальную отдачу с\u00a0первых недель работы. Команда способна развивать продукт самостоятельно, не\u00a0дожидаясь детально описанных задач или согласований. Все чётко понимают, на\u00a0какую цель работают, и\u00a0могут самостоятельно определять наиболее эффективный путь её\u00a0достижения. В\u00a0SmartHead реально думают о\u00a0ценности для конечного потребителя.',
            ],
        },
        {
            photo: antonVasserman,
            name: 'Антон Вассерман',
            position:
                'Управляющий директор\nДепартамент стратегического развития и\u00a0корпоративных коммуникаций, АК\u00a0БАРС\u00a0Банк',
            text: [
                'Я\u00a0начал сотрудничество со\u00a0SmartHead с\u00a0разработки мобильного приложения с\u00a0нуля. Совместная работа над продуктом дала блестящие результаты несмотря на\u00a0то, что наши первоначальные требования были довольно абстрактными.',
                'Они помогли сформировать окончательное видение, безупречно справились с\u00a0потоком изменений и\u00a0выкатили качественный продукт, уложившись в\u00a0сроки и\u00a0бюджет.',
                'Я\u00a0знаю, что могу положиться на\u00a0эту команду, и\u00a0очень надеюсь поработать с\u00a0ними на\u00a0новых проектах.',
            ],
        },
        {
            photo: gegamMkrtchjan,
            name: 'Гегам Мкртчян',
            position: 'Руководитель разработки рекламных спецпроектов VK',
            text: [
                'Когда я\u00a0познакомился с\u00a0командой SmartHead в\u00a02018\u00a0году, они выполняли для нас 15–25 проектов в\u00a0год. Спустя 5\u00a0лет совместной работы мы\u00a0увеличили этот объём в\u00a0несколько раз, успешно завершая более 200 проектов по\u00a0разработке интерактивных и\u00a0медиа-продуктов ежегодно.',
                'Могу уверенно сказать, SmartHead предоставляет лучший сервис на\u00a0рынке.',
                'Команда предлагает наилучшие варианты решений, а\u00a0результаты всегда тщательно протестированы. Если у\u00a0них есть своё видение креатива, они всегда высказывают свои предложения, что особенно ценно. А\u00a0больше всего мне нравится стремление к\u00a0экспериментам и\u00a0новым нестандартным решениям.',
                'Качество работы и\u00a0отличный сервис делает SmartHead не\u00a0просто надёжным партнёром, но\u00a0лидером, которого я\u00a0рекомендую всем на\u00a0рынке разработки.',
            ],
        },
        {
            photo: nataliaRomanova,
            name: 'Наталья Романова',
            position: 'Генеральный директор,\nCirque du\u00a0Soleil Россия',
            text: [
                'За\u00a0годы работы SmartHead стали для нас не\u00a0просто доверенным подрядчиком, а\u00a0надёжным партнёром и\u00a0единомышленником.',
                'Мы\u00a0очень ценим профессионализм ребят и\u00a0те\u00a0крутые результаты, которые они показали при разработке нашего нового сайта и\u00a0универсальной платформы для шоу Cirque du\u00a0Soleil в\u00a0России. Всегда приятно иметь дело с\u00a0командой, которая реализует высокотехнологичные решения, оставаясь проактивной и\u00a0дальновидной. В\u00a0SmartHead каждый сотрудник\u00a0\u2014 по-настоящему увлечённый профессионал, вкладывающийся в\u00a0успех вашего проекта.',
            ],
        },
        {
            photo: yuryFrolov,
            name: 'Yury Frolov',
            position:
                'UX\u00a0Design Director,\nAncestry Design System and DNA Product',
            text: [
                'I\u00a0worked with SmartHead’s front-end development team on\u00a0multiple occasions for various clients like Illumina, Invitae, and most recently Ancestry where they helped to\u00a0develop Ancestry’s new design system.',
                'SmartHead developers are rock-stars that were able to\u00a0rapidly code and test front-end libraries based on\u00a0our designs and specs. This allowed\u00a0us to\u00a0evaluate our designs in\u00a0live code and make necessary adjustments to\u00a0evolve our design system much further in\u00a0no\u00a0time.',
                'SmartHead’s team is\u00a0highly professional and a\u00a0great pleasure to\u00a0work with. Highly recommended to\u00a0anyone who’d want to\u00a0make their software product shine!',
            ],
        },

        {
            photo: dimaIvanov,
            name: 'Дима Иванов',
            position:
                'Digital-менеджер,\nProximity, цифровое агентство BBDO Group Creative',
            text: [
                'Я\u00a0высоко ценю их\u00a0мастерство в\u00a0разработке, управлении проектами и\u00a0коммуникациях, которое делает рабочий процесс приятным и\u00a0эффективным.',
                'SmartHead, определенно, самый надёжный технологический партнёр, с\u00a0которым мне посчастливилось работать. У\u00a0нас даже есть своя традиция. Когда проект запущен, команда собирается в\u00a0переговорке, а\u00a0я\u00a0звоню\u00a0им, чтобы сказать большое спасибо каждому за\u00a0отличную работу. Настоятельно рекомендую.',
            ],
        },

        {
            photo: victoriaChernishkina,
            name: 'Виктория Чернышкина',
            position:
                'Директор по специальным проектам,\nРоссийская деловая газета "Ведомости”',
            text: [
                'С\u00a0самого начала стало ясно, что мы\u00a0сделали правильный выбор: SmartHead всё делает быстро, чётко и\u00a0качественно.',
                'Они очень ответственно относятся к\u00a0проектам\u00a0и, выслушивая наши идеи, всегда предлагают лучшие решения. Безупречен не\u00a0только профессионализм их\u00a0технической команды, но\u00a0и\u00a0подход к\u00a0клиенту. Коммуникации всегда приятные и\u00a0прозрачные, а\u00a0управление изменениями оперативное. Спасибо за\u00a0отличную работу, SmartHead!',
            ],
        },

        {
            photo: annaChaplygina,
            name: 'Анна Чаплыгина',
            position:
                'Digital Producer,\nInstinct, креативное агентство BBDO group',
            text: [
                'SmartHead доказал, что он\u00a0надёжный технологический партнёр, готовый решить любую проблему.',
                'В\u00a0течение четырёх лет SmartHead был нашим партнёром по\u00a0digital production. За\u00a0это время мы\u00a0реализовали множество цифровых продуктов разного уровня сложности. Они очень внимательны к\u00a0деталям, укладываются в\u00a0ограничения сроков и\u00a0бюджета и\u00a0успешно работают с\u00a0жёсткими дедлайнами (важный навык в\u00a0рекламной индустрии!).',
            ],
        },
    ],
};
