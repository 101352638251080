// extracted by mini-css-extract-plugin
export var backwardButton = "index-module--backward-button--0ea72";
export var backwardButtonImg = "index-module--backward-button-img--7fcac";
export var backwardClickArea = "index-module--backward-click-area--d6ad9";
export var bullets = "index-module--bullets--fcc40";
export var bulletsMobile = "index-module--bullets-mobile--5a68d";
export var buttonBullet = "index-module--button-bullet--59c00";
export var buttonBulletActive = "index-module--button-bullet-active--8f26e";
export var buttonDisabled = "index-module--button-disabled--be287";
export var buttonHidden = "index-module--button-hidden--06f66";
export var container = "index-module--container--c5987";
export var forwardButton = "index-module--forward-button--82ee2";
export var forwardButtonImg = "index-module--forward-button-img--e77f1";
export var header = "index-module--header--0008b";
export var navigation = "index-module--navigation--4e6ae";
export var reviewsContainer = "index-module--reviews-container--e7d1f";
export var reviewsContainerEn = "index-module--reviews-container-en--58d11";
export var section = "index-module--section--288fe";
export var swiperSlide = "index-module--swiper-slide--c944e";
export var swiperWrapper = "index-module--swiperWrapper--cc221";
export var title = "index-module--title--e080c";