// extracted by mini-css-extract-plugin
export var bullets = "index-module--bullets--69fe1";
export var buttonBullet = "index-module--button-bullet--4fba1";
export var buttonBulletActive = "index-module--button-bullet-active--1bcac";
export var cases = "index-module--cases--e8b93";
export var casesBack = "index-module--cases-back--4b670";
export var casesContainer = "index-module--cases-container--951c9";
export var casesImage = "index-module--cases-image--e6661";
export var casesInfo = "index-module--cases-info--df2da";
export var casesInfoItem = "index-module--cases-info-item--93fee";
export var container = "index-module--container--780b9";
export var root = "index-module--root--125d9";